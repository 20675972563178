import React, { useState } from 'react';
import './App.css';
import Model from './Model';

function App() {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText("0x7f83dd49aE6371eEbB5d8C6eE63DB4e5E0B65318");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="App">
      <div className="Overlay" id="Overlay">
        <a className="Overlay">
          <img src="click_to_enter_screen.gif" alt="Logo" style={{ width: 'auto', height: '500px', maxWidth: '100%', cursor: 'pointer' }} />
        </a>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>CLICK TO ENTER</div>
        <script src="click_to_enter.js"></script>
      </div>
      <header className="App-header">
        <a className="logo"><img src="main-sanic.gif" alt="Logo" /></a>
      </header>
      <div style={{ textAlign: 'center', margin: '20px 0', cursor: 'pointer' }} onClick={copyToClipboard}>
        <span style={{ fontSize: '18px', fontWeight: 'bold', color: 'yellow' }}>
          CA: 0x7f83dd49aE6371eEbB5d8C6eE63DB4e5E0B65318
        </span>
        {copied && <span style={{ marginLeft: '10px', color: 'green' }}>Copied!</span>}
      </div>
      <div className="thumbnails">
        <div>
          <a href="https://dexscreener.com/fantom/0x7367e12291325f8c1730699ebd26eec2ea18e516"><img src="ring.gif" /></a>
          <div style={{ color: 'yellow', fontWeight: 'bold', fontSize: '1.2em' }}>Dexscreener</div>
        </div>
        <div>
          <a href="https://t.me/sanicftm"><img src="ring.gif" /></a>
          <div style={{ color: 'yellow', fontWeight: 'bold', fontSize: '1.2em' }}>TG</div>
        </div>
        <div>
          <a href="https://x.com/SanicOnSonic"><img src="ring.gif" /></a>
          <div style={{ color: 'yellow', fontWeight: 'bold', fontSize: '1.2em' }}>X</div>
        </div>
        <div>
          <a href="https://ftmscan.com/token/0x7f83dd49aE6371eEbB5d8C6eE63DB4e5E0B65318"><img src="ring.gif" /></a>
          <div style={{ color: 'yellow', fontWeight: 'bold', fontSize: '1.2em' }}>FTMScan</div>
        </div>
      </div>
      <div className="video-container" style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <a className="video-container"><img src="AAAAAAAAAA.gif" style={{ height: '100%', width: 'auto' }} /></a>
      </div>
      <div className="container">
        <div className="side-models left">
        <a className="logo"><img src="sanicsanicsanic.gif" /></a>
        </div>
        <div className="model-container-wrapper">
          <div className="model-container">
            <Model />
          </div>
        </div>
        <div className="side-models right">
        <a className="logo"><img src="sanicsanicsanic.gif" /></a>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px', color: 'yellow', fontSize: '14px' }}>©2024 by CUMON STEP IT UP!!!</div>
    </div>
  );
}

// Function to handle click events
const handleClick = () => {
  const overlay = document.getElementById("Overlay");
  if (overlay) {
    overlay.style.opacity = '0';
    overlay.style.zIndex = '-1';
  } else {
    console.error('Overlay element not found');
  }
};

// Add click event listener to the document
document.addEventListener('click', handleClick);

export default App;
