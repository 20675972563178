import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

function Model() {
  const mountRef = useRef(null);
  const rotationRef = useRef(true); // Reference to control rotation
  const userInteractingRef = useRef(false); // Track if the user is interacting

  useEffect(() => {
    // Scene, Camera, Renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(30, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true  // Enable alpha (transparency)
    });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    renderer.setClearColor(0x000000, 0);  // Set clear color to fully transparent
    mountRef.current.appendChild(renderer.domElement);

    // Ensure the scene background is not set to maintain transparency
    scene.background = null;

    // Adjust camera position to a side view (left or right)
    camera.position.set(20, 0, 0); // Increased distance to make model appear larger
    camera.lookAt(0, 0, 0); // Make the camera look at the center of the scene

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 2); // Maximum ambient light
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2); // Additional light for better visibility
    directionalLight.position.set(10, 10, 10).normalize();
    scene.add(directionalLight);

    // Load Model
    const loader = new GLTFLoader();
    loader.load('/sonic.glb', (gltf) => {
      const model = gltf.scene;
      scene.add(model);

      // Scale up the model to make it larger
      model.scale.set(10, 10, 10); // Increase scale by 50%

      // Position the model lower on the y-axis
      model.position.y -= 0; // Move down by 2 units to compensate for larger size

      // Initial rotation
      model.rotation.x = 0;
      model.rotation.y = 0;
      model.rotation.z = 0;

      // Animation
      const animate = function () {
        requestAnimationFrame(animate);
        if (rotationRef.current) {
          model.rotation.y += 0.1;  // Continue rotation
        }
        renderer.render(scene, camera);
      };

      animate();
    });

    // Orbit Controls for rotation only
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false; // Disable zoom with scroll
    controls.enablePan = false; // Disable panning
    controls.enableRotate = true; // Enable rotation with the cursor
    controls.rotateSpeed = 1.0; // Adjust rotation speed

    // Track when user starts interacting
    controls.addEventListener('start', () => {
      userInteractingRef.current = true;
      rotationRef.current = false; // Stop rotation during interaction
    });

    // Track when user stops interacting
    controls.addEventListener('end', () => {
      userInteractingRef.current = false;
      setTimeout(() => {
        if (!userInteractingRef.current) {
          rotationRef.current = true; // Resume rotation after interaction
        }
      }, 1000); // 1000 ms = 1 second delay
    });

    // Clean up event listeners on component unmount
    return () => {
      controls.dispose();
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div
      style={{ 
        width: '100%', 
        height: '100%',
        background: 'transparent'  // Ensure the container is also transparent
      }}
      ref={mountRef}
    />
  );
}

export default Model;
