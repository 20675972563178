import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let music_playing = false
function playMusic() {
    var music = document.getElementById("SanicOST") 
    if (music_playing === false){
      music_playing = true
      music.play()
    }
}


document.addEventListener("click",playMusic)

// document.addEventListener('DOMContentLoaded', function() {
//   // Get the Overlay element
//   const overlay = document.getElementById("Overlay") 

//   // Check if the overlay element exists
//   if (overlay) {
//     // Add a click event listener to the entire document
//     document.addEventListener('click', function() {
//       // Set opacity and z-index of the overlay element
//       overlay.style.opacity = '0';
//       overlay.style.zIndex = '-1';
//     });
//   } else {
//     console.error('Overlay element not found');
//   }
// });